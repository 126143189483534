import * as React from "react";
import type { SVGProps } from "react";
const SvgClassWorkout = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M7.5 20.25h9M13.5 17.248h6a2.25 2.25 0 0 0 2.25-2.25V6a2.25 2.25 0 0 0-2.25-2.25h-15A2.25 2.25 0 0 0 2.25 6v8.998a2.25 2.25 0 0 0 2.25 2.25h5a2.25 2.25 0 0 1 2.25 2.25v.75"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M14.827 10.068 10.5 7.57a.5.5 0 0 0-.75.433v4.996a.5.5 0 0 0 .75.433l4.327-2.498a.5.5 0 0 0 0-.866Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgClassWorkout;
