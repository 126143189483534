import * as React from "react";
import type { SVGProps } from "react";
const SvgWorkouts = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="m15.712 8.72-4.242 4.243 1.06 1.06 4.242-4.243-1.06-1.06Zm-3.182 5.303 4.242-4.243-1.06-1.06-4.242 4.243 1.06 1.06ZM7.5 3h9V1.5h-9V3Zm9-1.5h-9V3h9V1.5Zm3 12A7.5 7.5 0 0 1 12 21v1.5a9 9 0 0 0 9-9h-1.5ZM12 21a7.5 7.5 0 0 1-7.5-7.5H3a9 9 0 0 0 9 9V21Zm-7.5-7.5A7.5 7.5 0 0 1 12 6V4.5a9 9 0 0 0-9 9h1.5ZM12 6a7.5 7.5 0 0 1 7.5 7.5H21a9 9 0 0 0-9-9V6Z"
    />
  </svg>
);
export default SvgWorkouts;
