import * as React from "react";
import type { SVGProps } from "react";
const SvgNotes = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.5 9h9V7.5h-9V9ZM7.5 6h9V4.5h-9V6ZM7.5 12h6v-1.5h-6V12ZM12.02 16.299v3.18H15.2L18 16.682l.933-.931h-.002l4.308-4.308-3.18-3.182-8.04 8.039Zm6.977-4.857 1.061-1.061 1.061 1.06-1.06 1.06-1.062-1.06ZM13.52 16.92l4.416-4.418L19 13.562l-4.418 4.419H13.52V16.92Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18 19.5c0 .827-.673 1.5-1.5 1.5h-9c-.827 0-1.5-.673-1.5-1.5v-15C6 3.673 6.673 3 7.5 3h9c.827 0 1.5.673 1.5 1.5v3.697l1.5-1.5V4.5c0-1.654-1.346-3-3-3h-9c-1.654 0-3 1.346-3 3v15c0 1.654 1.346 3 3 3h9c1.654 0 3-1.346 3-3v-2.197l-1.5 1.5v.697Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgNotes;
