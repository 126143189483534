import * as React from "react";
import type { SVGProps } from "react";
const SvgArrowUpRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 23 22"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M17.472 14.88V5.029H7.619M17.51 4.99 5.49 17.01"
    />
  </svg>
);
export default SvgArrowUpRight;
