import * as React from "react";
import type { SVGProps } from "react";
const SvgWorkoutEquipment = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M3.75 18.75h3V5.25h-3v13.5ZM17.25 18.75h3V5.25h-3v13.5Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M9 12h8.25M20 12h2.5M1.5 12H4"
    />
  </svg>
);
export default SvgWorkoutEquipment;
