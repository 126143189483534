import * as React from "react";
import type { SVGProps } from "react";
const SvgWeatherSunny = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path stroke="currentColor" strokeWidth={1.5} d="M12 19.5v3M12 1.5v3" />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M17.25 12a5.25 5.25 0 1 1-10.501-.001A5.25 5.25 0 0 1 17.25 12Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="m6.697 17.304-2.122 2.12M19.425 4.575l-2.122 2.121M4.5 12h-3M22.5 12h-3M6.697 6.696l-2.122-2.12M19.425 19.425l-2.122-2.121"
    />
  </svg>
);
export default SvgWeatherSunny;
