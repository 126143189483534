import * as React from "react";
import type { SVGProps } from "react";
const SvgPlay = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M5.248 5.09V19.5a.75.75 0 0 0 1.125.65l13.487-7.787a.75.75 0 0 0 0-1.3L6.374 3.278l-1.5-.867"
    />
  </svg>
);
export default SvgPlay;
