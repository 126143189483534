import { VIDEO_PLAYER } from "@/libs/apis/onboarding/reactQueryCacheKeys";
import { getVideoPlayerByIdQuery } from "nvs-contentful-queries/dist/queries/videoPlayer";
import { contentfulRequest } from "nvs-contentful-queries/dist/setup/api";
import { VideoPlayer } from "nvs-types/dist/types/generated/contentful";

type Variables = { videoPlayerId: string };

export async function getVideoPlayerById(videoPlayerId: string) {
  const variables = { videoPlayerId: videoPlayerId };
  try {
    const result = await contentfulRequest<
      Variables,
      { videoPlayer: VideoPlayer }
    >({
      apiEndpoint: `${process.env.NEXT_PUBLIC_SITE_URL}/api/contentful/graphql`,
      query: getVideoPlayerByIdQuery,
      variables,
      credentials: "include",
      tags: [VIDEO_PLAYER, videoPlayerId],
    });

    return (result.data?.videoPlayer || {}) as VideoPlayer;
  } catch (e) {
    return {} as VideoPlayer;
  }
}
