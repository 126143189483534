import * as React from "react";
import type { SVGProps } from "react";
const SvgWeatherOvercastWind = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M9 21.75h10.5M13.5 18.75h-12M12.75 11.25a4.5 4.5 0 1 1 4.5 4.5H6.75a4.5 4.5 0 0 1 0-9"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M6.75 7.5a5.25 5.25 0 0 1 9.645-2.873M21.75 19.5a2.25 2.25 0 0 1-2.25 2.25"
    />
  </svg>
);
export default SvgWeatherOvercastWind;
