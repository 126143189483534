import * as React from "react";
import type { SVGProps } from "react";

const SvgPlay = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="11"
    height="12"
    viewBox="0 0 11 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.26898 6.6545C9.78037 6.36811 9.78036 5.63211 9.26895 5.34574L2.17894 1.37551C1.67901 1.09556 1.0625 1.45691 1.0625 2.0299V9.97071C1.0625 10.5437 1.67903 10.9051 2.17896 10.6251L9.26898 6.6545Z"
      fill="#111111"
      stroke="#111111"
      strokeWidth="1.5"
    />
  </svg>
);
export default SvgPlay;
