import * as React from "react";
import type { SVGProps } from "react";
const SvgWeatherNightOvercast = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M9.75 16.5a3.75 3.75 0 1 1 3.75 3.75H6a3.75 3.75 0 0 1 0-7.5"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M5.25 13.5a3.75 3.75 0 0 1 6-3"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M11.579 10.777A3.736 3.736 0 0 0 9 9.75"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M18.223 6.325c0-.65.134-1.27.374-1.833.19-.444-.27-.907-.7-.685a4.342 4.342 0 0 0-2.293 4.531c.286 1.89 1.864 3.407 3.763 3.631a4.347 4.347 0 0 0 2.069-.253c.448-.17.404-.819-.05-.973a4.669 4.669 0 0 1-3.163-4.418Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWeatherNightOvercast;
