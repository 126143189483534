import * as React from "react";
import type { SVGProps } from "react";
const SvgSport = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M9.75 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M14.25 16.5A3.75 3.75 0 1 1 18 20.25h-7.5a3.75 3.75 0 0 1 0-7.5"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M9.75 13.5a3.75 3.75 0 0 1 6-3"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M16.079 10.777A3.736 3.736 0 0 0 13.5 9.75M6.75 2V.5M10.109 3.392l1.06-1.061M2.33 11.169l1.062-1.061M11.5 6.75H13M.5 6.75H2M2.33 2.331l1.062 1.061"
    />
  </svg>
);
export default SvgSport;
