import * as React from "react";
import type { SVGProps } from "react";
const SvgAudioFeedback = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M13.59 10.41a2.25 2.25 0 0 1 0 3.181M10.41 13.59a2.25 2.25 0 0 1 0-3.181M15.712 8.288a5.248 5.248 0 0 1 0 7.424M8.288 15.712a5.248 5.248 0 0 1 0-7.424M17.834 6.166a8.25 8.25 0 0 1 0 11.668M6.166 17.834a8.25 8.25 0 0 1 0-11.668"
    />
  </svg>
);
export default SvgAudioFeedback;
