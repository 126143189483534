import * as React from "react";
import type { SVGProps } from "react";
const SvgWeatherSnow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M9.5 12h-5M19.25 12H14.5"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M14.25 12a2.25 2.25 0 1 1-4.502-.002A2.25 2.25 0 0 1 14.25 12Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M17.25 21v-3.75H21M9.349 21.977l2.65-2.65 2.653 2.65M6.75 21v-3.75H3M6.75 3v3.75H3M17.25 3v3.75H21"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="m9.349 21.974 2.65-2.651 2.653 2.65M14.651 2.026l-2.65 2.651-2.653-2.65M2.026 9.349l2.651 2.65-2.65 2.653M21.973 14.651l-2.65-2.65 2.65-2.653M10.195 10.32 6.66 6.785M17.09 17.215l-3.358-3.358M11.875 9.625v-5M11.875 19.375v-4.75M13.555 10.32l3.535-3.535M6.66 17.215l3.358-3.358"
    />
  </svg>
);
export default SvgWeatherSnow;
