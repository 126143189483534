import * as React from "react";
import type { SVGProps } from "react";
const SvgEffectInactive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M16.5 18.75a6.75 6.75 0 1 0 0-13.5 6.75 6.75 0 0 0 0 13.5Z"
    />
    <path
      fill="currentColor"
      d="M7.5 19.5c-.31 0-.62-.02-.93-.06l.19-1.49c.5.06.99.06 1.48 0l.18 1.49c-.31.04-.61.06-.92.06Zm-2.89-.58-.05-.02a7.33 7.33 0 0 1-1.58-.92l.9-1.2c.39.29.81.54 1.27.73l.09.05-.34.57-.29.79Zm5.8-.01-.58-1.38c.46-.19.89-.44 1.28-.74l.9 1.2c-.49.37-1.03.68-1.6.92Zm-8.89-2.39a7.47 7.47 0 0 1-.92-1.6l1.38-.59c.19.46.44.89.74 1.28l-1.2.91ZM.06 12.93a6.953 6.953 0 0 1 0-1.84l1.49.18c-.04.24-.05.48-.05.73s.02.5.05.75l-1.49.18Zm1.92-3.28L.58 9.1l.02-.05c.23-.55.54-1.07.9-1.55l1.2.9c-.28.38-.53.8-.72 1.25Zm1.89-2.43-.91-1.19c.49-.37 1.03-.68 1.6-.93l.59 1.38c-.46.19-.89.44-1.28.74Zm7.23-.02c-.38-.29-.8-.53-1.24-.71l.58-1.38c.56.24 1.09.54 1.56.9l-.9 1.19ZM6.74 6.05l-.19-1.49c.31-.04.63-.07.96-.06.29 0 .59.02.89.05l-.18 1.49c-.24-.03-.48-.03-.73-.04-.25 0-.5.02-.75.05Z"
    />
  </svg>
);
export default SvgEffectInactive;
