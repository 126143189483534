import * as React from "react";
import type { SVGProps } from "react";
const SvgHeartRate = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M21.153 11.434A5.251 5.251 0 0 0 19.8 4.917c-2.11-1.72-5.225-1.417-7.15.508l-.657.657-.78-.794C8.76 2.837 4.49 3.316 2.75 6.723c-1.014 1.985-.54 4.414 1.036 5.989l.038.038h3.158l2.517-2.5 4 4 1.5-1.5h4.042a.5.5 0 0 1 .353.854L11.99 21 5.9 14.9"
    />
  </svg>
);
export default SvgHeartRate;
