import * as React from "react";
import type { SVGProps } from "react";
const SvgProgramIntensity = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M17.75 3.75h-2.5v3h-4v3h-4v3h-3.5V18A2.25 2.25 0 0 0 6 20.25h12A2.25 2.25 0 0 0 20.25 18V3.75h-2.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgProgramIntensity;
