import * as React from "react";
import type { SVGProps } from "react";
const SvgWeatherNightClear = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M17.25 6.75a1.5 1.5 0 1 1-3.001-.001 1.5 1.5 0 0 1 3.001.001ZM8.25 9c0-2.293.736-4.413 1.983-6.14.134-.185-.018-.445-.241-.398-4.647.974-8.08 5.252-7.716 10.267.331 4.557 3.921 8.366 8.454 8.94a9.72 9.72 0 0 0 7.02-1.8c.184-.135.092-.42-.135-.443C12.352 18.859 8.25 14.414 8.25 9Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="m16.72 10.72 1.06 1.06M19.72 10.72l1.06 1.06M19.72 7.69l1.06 1.06"
    />
  </svg>
);
export default SvgWeatherNightClear;
