import * as React from "react";
import type { SVGProps } from "react";
const SvgDevice = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M15 21.75H9a2.25 2.25 0 0 1-2.25-2.25v-15A2.25 2.25 0 0 1 9 2.25h6a2.25 2.25 0 0 1 2.25 2.25v15A2.25 2.25 0 0 1 15 21.75Z"
      clipRule="evenodd"
    />
    <path stroke="currentColor" strokeWidth={1.5} d="M10.5 18.75h3" />
  </svg>
);
export default SvgDevice;
