import * as React from "react";
import type { SVGProps } from "react";
const SvgBrightness = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path stroke="currentColor" strokeWidth={1.5} d="M12 18v4.5M12 1.5V6" />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="m7.757 16.242-3.182 3.183M19.425 4.575l-3.182 3.183M6 12H1.5M22.5 12H18M7.757 7.758 4.575 4.575M19.425 19.425l-3.182-3.183"
    />
  </svg>
);
export default SvgBrightness;
