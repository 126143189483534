import * as React from "react";
import type { SVGProps } from "react";
const SvgRunner = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M17.25 8.25a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM9 15l-6.97 6.97M8.5 20.25h6.43c.67 0 1.01-.81.53-1.29l-4.15-4.15a1.49 1.49 0 0 1 0-2.12l1.66-1.66c.47-.47.14-1.28-.53-1.28H7.5L5.25 12M15.48 11.05l2.21 2.2c.59.58 1.53.58 2.12 0l2.21-2.2"
    />
  </svg>
);
export default SvgRunner;
