import * as React from "react";
import type { SVGProps } from "react";
const SvgFastForward = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M12.01 12V7.242a.75.75 0 0 1 1.126-.65l8.239 4.757a.75.75 0 0 1 0 1.3l-8.24 4.757a.75.75 0 0 1-1.125-.65v-1.828"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M11.643 11.365a.732.732 0 0 1 0 1.268l-8.267 4.773a.75.75 0 0 1-1.126-.65v-1.76"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M2.25 15.172v-7.93a.751.751 0 0 1 1.126-.65l6.632 3.829"
    />
  </svg>
);
export default SvgFastForward;
