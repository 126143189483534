import * as React from "react";
import type { SVGProps } from "react";
const SvgLightsOff = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="#7CB929"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="m5.03 5.03 13.94 13.94"
    />
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="m5.03 5.03 13.94 13.94"
    />
    <path
      fill="currentColor"
      d="M18.65 15.46c.54-1.04.85-2.21.85-3.46 0-4.14-3.36-7.5-7.5-7.5-1.25 0-2.43.31-3.46.85l10.11 10.11ZM5.36 8.54A7.358 7.358 0 0 0 4.5 12c0 4.14 3.36 7.5 7.5 7.5 1.25 0 2.42-.31 3.46-.86L5.36 8.54Z"
    />
  </svg>
);
export default SvgLightsOff;
