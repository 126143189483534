import * as React from "react";
import type { SVGProps } from "react";
const SvgWeatherRain = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M6.75 6.75a4.5 4.5 0 0 0 0 9h10.5a4.5 4.5 0 1 0-4.5-4.5"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M6.75 7.5a5.25 5.25 0 0 1 9.645-2.873M12.995 15.633l-2.245 3.889-1.5 2.598M7.744 15.635 5.5 19.522 4 22.12M18.326 15.493 16 19.521l-1.5 2.6"
    />
  </svg>
);
export default SvgWeatherRain;
