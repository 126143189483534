import * as React from "react";
import type { SVGProps } from "react";
const SvgPulsingDualLights = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M12 18v4.5M12 1.5V6"
    />
    <path
      fill="currentColor"
      d="M15.18 8.82a4.509 4.509 0 0 0-6.36 0 4.509 4.509 0 0 0 0 6.36"
      opacity={0.5}
    />
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="m7.76 16.24-3.18 3.18M19.42 4.58l-3.18 3.18M6 12H1.5M22.5 12H18M7.76 7.76 4.58 4.58M19.42 19.42l-3.18-3.18"
    />
    <path
      fill="currentColor"
      d="M8.82 15.18a4.509 4.509 0 0 0 6.36 0 4.509 4.509 0 0 0 0-6.36"
    />
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M12 18v4.5M12 1.5V6"
    />
  </svg>
);
export default SvgPulsingDualLights;
