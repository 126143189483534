import * as React from "react";
import type { SVGProps } from "react";
const SvgDeviceVertical = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M4.5 17.25A2.25 2.25 0 0 1 2.25 15V9A2.25 2.25 0 0 1 4.5 6.75M19.5 6.75A2.25 2.25 0 0 1 21.75 9v6a2.25 2.25 0 0 1-2.25 2.25"
      opacity={0.2}
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M9 21.75h6a2.25 2.25 0 0 0 2.25-2.25v-15A2.25 2.25 0 0 0 15 2.25H9A2.25 2.25 0 0 0 6.75 4.5v15A2.25 2.25 0 0 0 9 21.75Z"
      clipRule="evenodd"
    />
    <path stroke="currentColor" strokeWidth={1.5} d="M13.5 18.75h-3" />
  </svg>
);
export default SvgDeviceVertical;
