import * as React from "react";
import type { SVGProps } from "react";
const SvgBadgeExperience = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 28"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.51 1.077c.684 0 1.347.183 1.937.528l7.586 4.48a3.993 3.993 0 0 1 1.936 3.435v8.96c0 1.41-.747 2.725-1.936 3.435l-7.586 4.48a3.823 3.823 0 0 1-3.872 0l-7.587-4.48a3.993 3.993 0 0 1-1.936-3.435V9.52c0-1.41.747-2.725 1.936-3.435l7.587-4.48a3.822 3.822 0 0 1 1.936-.528Zm0-1.077c-.852 0-1.704.226-2.462.668l-7.586 4.48C.947 6.042 0 7.722 0 9.52v8.96c0 1.799.947 3.468 2.462 4.372l7.576 4.48c.758.453 1.61.668 2.462.668.852 0 1.704-.226 2.462-.668l7.576-4.48C24.053 21.96 25 20.28 25 18.48V9.52a5.102 5.102 0 0 0-2.462-4.372L14.962.668A4.788 4.788 0 0 0 12.5 0h.01Z"
    />
    <path
      fill="currentColor"
      d="M12.34 6.938c-1.877 0-3.9 1.499-3.9 3.932 0 2.432 3.5 10.171 3.646 10.503l.254.565.254-.565c.146-.332 3.646-8.145 3.646-10.504 0-2.358-2.023-3.93-3.9-3.93Zm0 5.59c-.969 0-1.744-.799-1.744-1.77 0-.97.787-1.768 1.744-1.768s1.744.798 1.744 1.769c0 .97-.787 1.769-1.744 1.769Z"
    />
  </svg>
);
export default SvgBadgeExperience;
