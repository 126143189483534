import * as React from "react";
import type { SVGProps } from "react";
const SvgClosedCaption = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m19.5 12.81-.007.105a3.75 3.75 0 0 1-.303 1.287 3.093 3.093 0 0 1-1.741 1.669 3.6 3.6 0 0 1-1.308.232 3.723 3.723 0 0 1-1.578-.325 3.52 3.52 0 0 1-1.185-.887 3.966 3.966 0 0 1-.741-1.307A4.835 4.835 0 0 1 12.381 12c0-.552.086-1.082.256-1.574.17-.493.419-.933.74-1.306a3.557 3.557 0 0 1 1.185-.892c.463-.22.995-.33 1.579-.33.434 0 .851.06 1.237.18.393.123.739.301 1.033.531.297.23.542.516.727.848.184.331.3.715.344 1.141l.012.11h-1.526l-.02-.075a2.618 2.618 0 0 0-.226-.587 1.574 1.574 0 0 0-.364-.453 1.61 1.61 0 0 0-.519-.298 2.112 2.112 0 0 0-.698-.106c-.388 0-.727.078-1.007.233a1.995 1.995 0 0 0-.688.627 2.87 2.87 0 0 0-.397.905 4.224 4.224 0 0 0 0 2.091c.085.336.219.64.397.905.175.26.407.471.688.627.28.155.619.233 1.007.233.283 0 .536-.048.751-.146.213-.097.401-.233.554-.403.155-.17.278-.375.366-.606.089-.232.149-.486.176-.756l.009-.09H19.5Zm-9.384 0h1.503l-.007.105c-.03.47-.132.902-.302 1.287a3.093 3.093 0 0 1-1.742 1.669 3.6 3.6 0 0 1-1.308.232 3.723 3.723 0 0 1-1.578-.325 3.52 3.52 0 0 1-1.185-.887 3.966 3.966 0 0 1-.741-1.307A4.835 4.835 0 0 1 4.5 12c0-.552.086-1.082.256-1.574.17-.493.419-.933.74-1.306a3.557 3.557 0 0 1 1.185-.892c.464-.22.995-.33 1.579-.33.435 0 .852.06 1.238.18.392.123.739.301 1.032.531.297.23.542.516.727.848.184.331.3.715.345 1.141l.011.11h-1.526l-.02-.075a2.618 2.618 0 0 0-.226-.587 1.615 1.615 0 0 0-.362-.453 1.632 1.632 0 0 0-.52-.298c-.2-.07-.435-.106-.699-.106-.388 0-.727.078-1.006.233a1.989 1.989 0 0 0-.689.627 2.879 2.879 0 0 0-.397.905 4.224 4.224 0 0 0 0 2.091c.086.338.22.643.397.905.175.26.408.471.689.627.279.155.618.233 1.006.233.283 0 .536-.048.751-.146.214-.096.4-.232.554-.403.157-.173.28-.377.366-.605.09-.233.149-.487.176-.757l.009-.09Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M19.5 18.75h-15a2.25 2.25 0 0 1-2.25-2.25v-9A2.25 2.25 0 0 1 4.5 5.25h15a2.25 2.25 0 0 1 2.25 2.25v9a2.25 2.25 0 0 1-2.25 2.25Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgClosedCaption;
