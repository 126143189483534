import * as React from "react";
import type { SVGProps } from "react";
const SvgTrail = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M4.5 17.25H3M3.75 15V7.5a3.75 3.75 0 0 1 7.5 0v8.25a4.5 4.5 0 0 0 9 0V7.5a2.25 2.25 0 0 0-4.5 0V14a3.25 3.25 0 0 1-3.25 3.25H6"
    />
  </svg>
);
export default SvgTrail;
