import * as React from "react";
import type { SVGProps } from "react";
const SvgDeviceRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M17.25 19.5A2.25 2.25 0 0 1 15 21.75H9a2.25 2.25 0 0 1-2.25-2.25M6.75 4.5A2.25 2.25 0 0 1 9 2.25h6a2.25 2.25 0 0 1 2.25 2.25"
      opacity={0.2}
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M21.75 15V9a2.25 2.25 0 0 0-2.25-2.25h-15A2.25 2.25 0 0 0 2.25 9v6a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 15Z"
      clipRule="evenodd"
    />
    <path stroke="currentColor" strokeWidth={1.5} d="M18.75 10.5v3" />
  </svg>
);
export default SvgDeviceRight;
