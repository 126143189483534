import * as React from "react";
import type { SVGProps } from "react";
const SvgElevationChange = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M2.25 8.913 7.625 5.81a2.25 2.25 0 0 1 2.25 0l4.25 2.455a2.254 2.254 0 0 0 2.25 0L21.75 5.16"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M10.306 18.75H4.75a2.5 2.5 0 0 1-2.5-2.5v-3.87l5.375-3.103a2.25 2.25 0 0 1 2.25 0l4.25 2.453a2.245 2.245 0 0 0 2.25 0l4.25-2.453a.75.75 0 0 1 1.125.65v6.323a2.5 2.5 0 0 1-2.5 2.5h-8.944Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgElevationChange;
