import * as React from "react";
import type { SVGProps } from "react";
const SvgBatteryLow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M16.75 19.25a1 1 0 0 1-1 1h-7.5a1 1 0 0 1-1-1V7.75a1 1 0 0 1 1-1h7.5a1 1 0 0 1 1 1v11.5Z"
    />
    <path fill="currentColor" d="M14.5 13.5h-5V18h5v-4.5Z" />
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M9.5 3.75h5"
    />
  </svg>
);
export default SvgBatteryLow;
