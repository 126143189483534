import { ReadonlyURLSearchParams } from "next/navigation";

export const createQueryString = (
  searchParams: ReadonlyURLSearchParams | null,
  newKey?: string,
  newValue?: string,
) => {
  const params = new URLSearchParams(searchParams?.toString());

  if (newKey && newValue) {
    params.set(newKey, newValue);
  }

  return params.toString();
};
