"use client";
import { IconButton } from "nvs-design-system/dist/IconButton";
import { IconButtonType } from "nvs-design-system/dist/IconButton/types";
import { Close } from "nvs-ds-icons";
import BaseModal from "../BaseModal";
import { usePathname, useSearchParams } from "next/navigation";
import Link from "next/link";
import { useVideoPlayerById } from "./hooks";

const VIDEO_PLAYER_PARAM_KEY = "videoPlayer";

export function VideoPlayer() {
  const path = usePathname();
  const params = useSearchParams();

  const videoId = params?.get(VIDEO_PLAYER_PARAM_KEY);
  const isOpen = !!videoId;

  const { data } = useVideoPlayerById(videoId ?? "", isOpen);

  const closeVideoPlayerLink = () => {
    const nextSearchParams = new URLSearchParams(params?.toString());
    nextSearchParams.delete(VIDEO_PLAYER_PARAM_KEY);
    return `${path}?${nextSearchParams}`;
  };

  if (!data?.asset?.url) return null;
  return (
    <BaseModal isOpen={isOpen}>
      <div className="h-full w-full relative overflow-hidden flex items-center">
        <video
          src={data?.asset.url}
          className="w-full h-full aspect[9/16] desktop:aspect-video"
          playsInline
          autoPlay={true}
          controls
          aria-label={data?.asset?.description ?? ""}
        />

        <Link href={closeVideoPlayerLink()}>
          <IconButton
            aria-label="close video player"
            className="absolute top-3 right-3 desktop:top-5 desktop:right-5 w-11 h-11 text-white"
            buttonType={IconButtonType.no_outline}
          >
            <Close />
          </IconButton>
        </Link>
      </div>
    </BaseModal>
  );
}
