import * as React from "react";
import type { SVGProps } from "react";
const SvgAppleWatch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M15.75 18.75h-7.5a3 3 0 0 1-3-3v-7.5a3 3 0 0 1 3-3h7.5a3 3 0 0 1 3 3v7.5a3 3 0 0 1-3 3ZM13.5 21.75h-3a2.25 2.25 0 0 1-2.25-2.25v-.75h7.5v.75a2.25 2.25 0 0 1-2.25 2.25ZM10.5 2.25h3a2.25 2.25 0 0 1 2.25 2.25v.75h-7.5V4.5a2.25 2.25 0 0 1 2.25-2.25Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgAppleWatch;
