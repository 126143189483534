import * as React from "react";
import type { SVGProps } from "react";
const SvgNikeSwooshBadge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 10 10"
    {...props}
  >
    <g stroke="currentColor" clipPath="url(#nike-swoosh-badge_svg__a)">
      <path
        fill="#B7FF2C"
        d="M8.593 6.694v.04a1.11 1.11 0 0 1-.556.923L5.103 9.351a1.114 1.114 0 0 1-1.113 0L1.057 7.657A1.112 1.112 0 0 1 .5 6.694V3.306a1.114 1.114 0 0 1 .557-.964L3.989.65a1.114 1.114 0 0 1 1.114 0l2.933 1.693c.345.199.557.566.557.964v3.388z"
      />
      <path
        fill="currentColor"
        strokeWidth={0.15}
        d="M3.367 5.966c-.48.203-1.234.436-1.549-.068-.175-.28-.064-.715.194-1.113.165-.254.377-.487.588-.72-.118.19-.449.827-.015 1.146.168.121.465.134.835.038l4.406-1.164-4.46 1.882v-.001z"
      />
    </g>
    <defs>
      <clipPath id="nike-swoosh-badge_svg__a">
        <path fill="#fff" d="M0 0h9.093v10H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgNikeSwooshBadge;
