import * as React from "react";
import type { SVGProps } from "react";
const SvgLaceDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path stroke="currentColor" strokeWidth={1.5} d="M6 18.752h12" />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M6 14.253h12M6 9.75h12M6 5.25h12"
      opacity={0.2}
    />
  </svg>
);
export default SvgLaceDown;
