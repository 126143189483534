"use client";
import { useDisableBodyScroll } from "@/src/hooks";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";

type BaseModalProps = React.PropsWithChildren<{
  isOpen: boolean;
  className?: string;
}>;

function BaseModal({ children, isOpen, className }: BaseModalProps) {
  useDisableBodyScroll(isOpen);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className={clsx(
            "modal fixed inset-0 w-full h-full z-modal overflow-hidden bg-gray-950",
            className,
          )}
          transition={{ duration: 0.2 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default BaseModal;
