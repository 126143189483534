import * as React from "react";
import type { SVGProps } from "react";
const SvgStages = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M7.5 2.25H18a2.25 2.25 0 0 1 2.25 2.25V18"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M15 21.75H6a2.25 2.25 0 0 1-2.25-2.25v-12A2.25 2.25 0 0 1 6 5.25h9a2.25 2.25 0 0 1 2.25 2.25v12A2.25 2.25 0 0 1 15 21.75Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M14.21 10.667 9.435 15.44l-2.652-2.652"
    />
  </svg>
);
export default SvgStages;
