import * as React from "react";
import type { SVGProps } from "react";
const SvgDotSwooshId = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 95 75"
    {...props}
  >
    <mask
      id="dot-swoosh-id_svg__a"
      width={73}
      height={74}
      x={11}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path fill="#D9D9D9" d="M11.5.59h72v73h-72z" />
    </mask>
    <g mask="url(#dot-swoosh-id_svg__a)">
      <rect
        width={52.5}
        height={82.639}
        x={21.25}
        y={1.34}
        fill="currentColor"
        stroke="#fff"
        strokeWidth={1.5}
        rx={2.25}
      />
      <rect
        width={40.5}
        height={13.5}
        x={27.25}
        y={7.4}
        stroke="#fff"
        strokeWidth={1.5}
        rx={1.25}
      />
      <rect
        width={40.5}
        height={40.5}
        x={27.25}
        y={37.34}
        stroke="#fff"
        strokeWidth={1.5}
        rx={1.25}
      />
      <rect
        width={40.5}
        height={4.779}
        x={27.25}
        y={26.34}
        stroke="#fff"
        strokeWidth={1.5}
        rx={2.39}
      />
      <path
        fill="#B7FF2C"
        d="M34 57.669v3.98h3.957v-3.98zM62 53.756l-16.53 7.02c-1.779.756-4.573 1.624-5.739-.253-.648-1.046-.236-2.669.721-4.153.61-.948 1.395-1.816 2.183-2.682-.442.707-1.663 3.084-.057 4.264.626.46 1.727.515 3.091.145z"
      />
    </g>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M1.5 73.59h92"
    />
  </svg>
);
export default SvgDotSwooshId;
