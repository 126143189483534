import * as React from "react";
import type { SVGProps } from "react";
const SvgMusicNone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.5 18a1.498 1.498 0 0 1-.814-2.756l2.07 2.07c-.267.412-.73.686-1.256.686ZM6 19.5c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5 1.5.673 1.5 1.5-.673 1.5-1.5 1.5Zm16.5.437-3.04-3.041-3.356-3.356h-.001L11.563 9l-1.5-1.5L9 6.437 7.529 4.965v.001L5.561 2.998 4.5 4.06l3 3v8.358A2.968 2.968 0 0 0 6 15c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3V8.559l5.62 5.619A2.967 2.967 0 0 0 13.5 16.5c0 1.654 1.346 3 3 3 .941 0 1.776-.438 2.322-1.119l2.618 2.617 1.06-1.061ZM9.75 4.5h7.5a.75.75 0 0 1 .75.75V7.5h-5.815l1.5 1.5H18v4.315l1.5 1.5V5.25C19.5 4.01 18.491 3 17.25 3h-7.5c-.573 0-1.09.222-1.489.576l1.07 1.07a.73.73 0 0 1 .42-.146Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMusicNone;
