import * as React from "react";
import type { SVGProps } from "react";
const SvgBurn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M15 11.25c-2.077 0-2.716-4.312-2.912-8.211-.04-.787-1.093-.998-1.426-.285C8.694 6.964 5.25 12.321 5.25 15a6.75 6.75 0 1 0 13.5 0c0-3.75-3.5-6.75-3.5-10.5"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M8.876 18.626c0-1.277 1.693-3.214 2.587-4.146a.736.736 0 0 1 1.074 0c.894.932 2.587 2.87 2.587 4.146a3.123 3.123 0 1 1-6.248 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBurn;
