import * as React from "react";
import type { SVGProps } from "react";
const SvgTreadmill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M18.75 20.25a1.5 1.5 0 0 0 0-3H5.25a1.5 1.5 0 0 0 0 3h13.5Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M17.265 16.958 20 6.75h-8.75a1.5 1.5 0 0 0 0 3h5.25M17.244 7.05l1.05-3.917"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M6.75 18.75a1.5 1.5 0 1 1-3.001-.001 1.5 1.5 0 0 1 3.001.001ZM20.25 18.75a1.5 1.5 0 1 1-3.001-.001 1.5 1.5 0 0 1 3.001.001Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTreadmill;
