import * as React from "react";
import type { SVGProps } from "react";
const SvgMemberBadge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <g clipPath="url(#member-badge_svg__a)">
      <path fill="#B7FF2C" d="M20 0H0v20h20V0z" />
      <path
        fill="currentColor"
        d="M9.954 13.725c-2.129.905-5.469 1.94-6.86-.303-.777-1.244-.285-3.185.86-4.954.729-1.13 1.666-2.17 2.603-3.205-.519.847-1.988 3.68-.067 5.099.746.541 2.062.598 3.698.171L29.71 5.35 9.951 13.728l.003-.003z"
      />
      <path fill="#4072FF" d="M0 0h20v20H0z" />
      <path
        fill="#fff"
        d="M11.587 8.844c.01.047.068.07.11.042l3.155-2.074c.572-.375 1.224-.769 1.905-.648.528.093.962.49 1.25.902.152.217.264.461.307.72.16.986-.643 1.516-1.362 1.971a51.017 51.017 0 0 0-4.33 3.072c-.793.626-1.655 1.31-2.68 1.337-.945.025-1.849-.566-2.27-1.38-.391-.754-.391-1.664-.093-2.458.017-.044-.04-.08-.075-.047-.823.75-1.59 1.555-2.292 2.41-.29.352-.581.723-.993.938-.627.325-1.462.194-1.976-.28-1.406-1.29.09-3.122 1.044-4.166 1.16-1.271 2.63-2.274 4.234-2.96.615-.264 1.293-.487 1.95-.346.662.141 1.193.639 1.518 1.211.308.543.458 1.152.597 1.757v-.001z"
      />
    </g>
    <defs>
      <clipPath id="member-badge_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgMemberBadge;
