import * as React from "react";
import type { SVGProps } from "react";
const SvgRewind = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M11.99 12V7.242a.75.75 0 0 0-1.126-.65l-8.239 4.757a.75.75 0 0 0 0 1.3l8.239 4.757a.75.75 0 0 0 1.126-.65v-1.828"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M12.356 11.365a.732.732 0 0 0 0 1.268l8.268 4.773a.75.75 0 0 0 1.126-.65v-1.76"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M21.75 15.172v-7.93a.751.751 0 0 0-1.126-.65l-6.632 3.829"
    />
  </svg>
);
export default SvgRewind;
