import * as React from "react";
import type { SVGProps } from "react";
const SvgConverse = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.045 10.99V7.73L6.19 10.37 3.115 9.35l1.94 2.64-1.905 2.64 3.045-1.005 1.855 2.64v-3.26l3.11-1.02-3.115-.995Zm12.84 1.02-6.82 7.49H8.21l6.825-7.49L8.21 4.5h5.856l6.819 7.51Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgConverse;
