import * as React from "react";
import type { SVGProps } from "react";
const SvgMusic = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path stroke="currentColor" strokeWidth={1.5} d="M8.25 18V6M18.75 16.5V6" />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M18.75 8.25H8.25v-3a1.5 1.5 0 0 1 1.5-1.5h7.5a1.5 1.5 0 0 1 1.5 1.5v3ZM8.25 18a2.25 2.25 0 1 1-4.502-.002A2.25 2.25 0 0 1 8.25 18ZM18.75 16.5a2.25 2.25 0 1 1-4.502-.002 2.25 2.25 0 0 1 4.502.002Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMusic;
