import * as React from "react";
import type { SVGProps } from "react";
const SvgCube = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 21 25"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m10.392 1.366 9.642 5.567v11.134l-9.642 5.567L.75 18.067V6.933l9.642-5.567Z"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="m.5 7 9.871 5.671L20 7.001M10.371 12.25v11.657"
    />
  </svg>
);
export default SvgCube;
