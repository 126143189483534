import * as React from "react";
import type { SVGProps } from "react";
const SvgTrack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M12 21.75a6.002 6.002 0 0 1-6.002-6.002V8.252a6.002 6.002 0 0 1 12.004 0v7.496A6.002 6.002 0 0 1 12 21.75Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M11.998 18.75a3 3 0 0 1-3-3V8.249a3 3 0 0 1 6 0v7.501a3 3 0 0 1-3 3Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M5.998 15.75h2.887M9.055 13.5h2.887M12.055 10.5h2.887M15.305 8.25h2.583"
    />
    <path stroke="currentColor" strokeWidth={1.5} d="m11.961 7.5.037 9" />
  </svg>
);
export default SvgTrack;
