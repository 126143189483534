import * as React from "react";
import type { SVGProps } from "react";
const SvgAdaptLight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M21.75 12A9.75 9.75 0 0 1 12 21.75 9.75 9.75 0 0 1 2.25 12 9.75 9.75 0 0 1 12 2.25 9.75 9.75 0 0 1 21.75 12Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.5 12c0 4.143-3.357 7.5-7.5 7.5A7.499 7.499 0 0 1 4.5 12c0-4.143 3.357-7.5 7.5-7.5s7.5 3.357 7.5 7.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgAdaptLight;
