import * as React from "react";
import type { SVGProps } from "react";
const SvgBadgeGaming = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 26 28"
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.01 1.077c.684 0 1.347.183 1.937.528l7.586 4.48a3.993 3.993 0 0 1 1.936 3.435v8.96c0 1.41-.747 2.725-1.936 3.435l-7.586 4.48a3.823 3.823 0 0 1-3.872 0l-7.587-4.48a3.993 3.993 0 0 1-1.936-3.435V9.52c0-1.41.747-2.725 1.936-3.435l7.587-4.48a3.822 3.822 0 0 1 1.936-.528Zm0-1.077c-.852 0-1.704.226-2.462.668l-7.586 4.48C1.447 6.042.5 7.722.5 9.52v8.96c0 1.799.947 3.468 2.462 4.372l7.576 4.48c.758.453 1.61.668 2.462.668.852 0 1.704-.226 2.462-.668l7.576-4.48c1.515-.893 2.462-2.573 2.462-4.372V9.52a5.102 5.102 0 0 0-2.462-4.372L15.462.668A4.788 4.788 0 0 0 13 0h.01Z"
    />
    <path
      fill="currentColor"
      d="M18.322 10.955C17.491 9.852 16.844 9.4 16.09 9.4H9.903c-.754 0-1.401.452-2.233 1.555-.75.998-3.233 5.058-1.655 6.696.458.478.993.726 1.586.745.936.027 1.963-.504 3.157-1.615.958-.893 1.644-.904 2.094-.911.054 0 .108 0 .139-.004.046 0 .1 0 .154.004.45.007 1.135.018 2.094.91 1.17 1.089 2.183 1.62 3.095 1.62h.065c.593-.015 1.124-.267 1.586-.746 1.579-1.641-.908-5.7-1.659-6.699h-.004Zm-6.848 2.354h-.635v.621c0 .286-.239.52-.532.52a.527.527 0 0 1-.53-.52v-.621H9.14a.527.527 0 0 1-.531-.52c0-.286.238-.52.531-.52h.635v-.62c0-.287.239-.52.531-.52.293 0 .532.233.532.52v.62h.635c.293 0 .531.234.531.52 0 .286-.239.52-.531.52Zm4.65-2.18c.347 0 .632.274.632.617a.623.623 0 0 1-.632.618.622.622 0 0 1-.631-.618c0-.343.28-.617.631-.617Zm-1.066 2.274a.625.625 0 0 1-.631-.618c0-.342.28-.617.63-.617.351 0 .632.275.632.617a.622.622 0 0 1-.631.618Zm1.066 1.043a.625.625 0 0 1-.631-.617c0-.343.28-.618.631-.618.35 0 .632.275.632.617a.622.622 0 0 1-.632.618Zm1.067-1.043a.625.625 0 0 1-.632-.618c0-.342.281-.617.632-.617.35 0 .63.275.63.617a.622.622 0 0 1-.63.618Z"
    />
  </svg>
);
export default SvgBadgeGaming;
