import * as React from "react";
import type { SVGProps } from "react";
const SvgDot = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 39 39"
    {...props}
  >
    <path fill="#fff" d="M0 0h38.5v38.5H0z" />
  </svg>
);
export default SvgDot;
