import * as React from "react";
import type { SVGProps } from "react";
const SvgPrograms = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M15.25 5.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25H6A2.25 2.25 0 0 1 3.75 18V7.5A2.25 2.25 0 0 1 6 5.25h2.75"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M9 6.75h6v-3H9v3Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M15.713 10.667 10.94 15.44l-2.652-2.651"
    />
  </svg>
);
export default SvgPrograms;
