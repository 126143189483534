import * as React from "react";
import type { SVGProps } from "react";
const SvgNby = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.98}
      d="M11.27 5.97H4.32v13.883h13.863V12.9"
      opacity={0.5}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.99}
      d="M19.002 3.425s.833-.34 1.55.377c.717.717.377 1.55.377 1.55l-8.31 8.306a.992.992 0 0 1-.398.243l-2.606.835.835-2.606a.993.993 0 0 1 .244-.399l8.308-8.306Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={0.99}
      d="M12.601 13.693s.34-.833-.376-1.55c-.717-.717-1.55-.378-1.55-.378"
    />
  </svg>
);
export default SvgNby;
