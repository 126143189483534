import * as React from "react";
import type { SVGProps } from "react";
const SvgWeatherHail = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M6.75 6.75a4.5 4.5 0 0 0 0 9h10.5a4.49 4.49 0 0 0 3.182-1.318 4.49 4.49 0 0 0 1.318-3.182 4.49 4.49 0 0 0-1.318-3.182A4.49 4.49 0 0 0 17.25 6.75a4.49 4.49 0 0 0-3.182 1.318 4.49 4.49 0 0 0-1.318 3.182"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M6.75 7.5a5.25 5.25 0 0 1 9.645-2.873M15.25 20.821l-.75 1.3"
    />
    <path
      fill="currentColor"
      d="m9.35 20.446-.75 1.3 1.3.75.75-1.3-1.3-.75Zm2.995-5.188L10.1 19.146l1.3.75 2.245-3.888-1.3-.75ZM4.1 20.446l-.75 1.3 1.3.75.75-1.3-1.3-.75Zm2.994-5.187L4.85 19.146l1.3.75 2.244-3.887-1.3-.75Z"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M18.326 15.493 16 19.521"
    />
  </svg>
);
export default SvgWeatherHail;
