import * as React from "react";
import type { SVGProps } from "react";
const SvgWeatherWind = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M14.25 6a3.75 3.75 0 0 1-3.75 3.75h-9M17.25 10.5a2.25 2.25 0 1 1 2.25 2.25h-18M12.75 18A2.25 2.25 0 1 0 15 15.75H1.5"
    />
  </svg>
);
export default SvgWeatherWind;
