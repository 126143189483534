import * as React from "react";
import type { SVGProps } from "react";
const SvgLoosen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M18 18.75H6M15 9.75H9M15 14.25H9M15 5.25H9"
    />
  </svg>
);
export default SvgLoosen;
