import * as React from "react";
import type { SVGProps } from "react";
const SvgEffectActive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M7.5 12c0-2.72 1.22-5.16 3.13-6.81A7.43 7.43 0 0 0 7.5 4.5C3.36 4.5 0 7.86 0 12c0 4.14 3.36 7.5 7.5 7.5 1.12 0 2.18-.25 3.13-.69A8.987 8.987 0 0 1 7.5 12Z"
    />
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M16.5 18.75a6.75 6.75 0 1 0 0-13.5 6.75 6.75 0 0 0 0 13.5Z"
    />
  </svg>
);
export default SvgEffectActive;
