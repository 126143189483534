import * as React from "react";
import type { SVGProps } from "react";
const SvgBadgeArFilter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 26 28"
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.26 1.077c.684 0 1.347.183 1.937.528l7.586 4.48a3.993 3.993 0 0 1 1.936 3.435v8.96c0 1.41-.747 2.725-1.936 3.435l-7.586 4.48a3.823 3.823 0 0 1-3.872 0l-7.587-4.48a3.993 3.993 0 0 1-1.936-3.435V9.52c0-1.41.747-2.725 1.936-3.435l7.587-4.48a3.822 3.822 0 0 1 1.936-.528Zm0-1.077c-.852 0-1.704.226-2.462.668l-7.586 4.48C1.697 6.042.75 7.722.75 9.52v8.96c0 1.799.947 3.468 2.462 4.372l7.576 4.48c.758.453 1.61.668 2.462.668.852 0 1.704-.226 2.462-.668l7.576-4.48c1.515-.893 2.462-2.573 2.462-4.372V9.52a5.102 5.102 0 0 0-2.462-4.372L15.712.668A4.788 4.788 0 0 0 13.25 0h.01Z"
    />
    <path
      fill="currentColor"
      d="m6.277 13.998 6.735 3.712 6.738-3.708-2.6-1.433-3.78 2.081-.358.196-.357-.196-3.778-2.084-2.6 1.432Z"
    />
    <path
      fill="currentColor"
      d="m6.277 10.288 6.735 3.713 6.738-3.708-6.735-3.712M6.277 17.707l6.735 3.712 6.738-3.706-2.6-1.433-3.78 2.08-.358.197-4.135-2.28-2.6 1.43Z"
    />
  </svg>
);
export default SvgBadgeArFilter;
