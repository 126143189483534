import * as React from "react";
import type { SVGProps } from "react";
const SvgVolumeHigh = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M3.75 15.75h4.5v-7.5h-4.5v7.5Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="m8.25 8.25 4.72-4.72a.75.75 0 0 1 1.28.531V19.94a.75.75 0 0 1-1.28.531l-4.72-4.72M17.25 9v6M20.25 7.5v9"
    />
  </svg>
);
export default SvgVolumeHigh;
