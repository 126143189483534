import { createQueryString } from "@/src/helpers/createQueryString";
import { useQuery } from "@tanstack/react-query";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { getVideoPlayerById } from "./services";
import { VIDEO_PLAYER } from "@/libs/apis/onboarding/reactQueryCacheKeys";

export const VIDEO_PLAYER_PARAM_KEY = "videoPlayer";

export const useCreateVideoPlayerPath = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const createPath = (videoId: string) => {
    const queryStringWithVideoPlayer = createQueryString(
      searchParams,
      VIDEO_PLAYER_PARAM_KEY,
      videoId,
    );

    const videoPlayerPath = pathname + "?" + queryStringWithVideoPlayer;
    return videoPlayerPath;
  };

  return createPath;
};

export const useVideoPlayerById = (videoId: string, enabled: boolean) => {
  return useQuery({
    enabled: enabled,
    queryFn: async () => await getVideoPlayerById(videoId),
    queryKey: [VIDEO_PLAYER, videoId],
    suspense: true,
  });
};
