import * as React from "react";
import type { SVGProps } from "react";
const SvgAdapt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M16.5 17.28a5.28 5.28 0 0 0 0-10.56H7.529a5.28 5.28 0 0 0 0 10.56H16.5Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.5 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM19.5 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgAdapt;
