import * as React from "react";
import type { SVGProps } from "react";
const SvgDualLightsColorChip = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M12 18.75a6.75 6.75 0 1 0 0-13.5 6.75 6.75 0 0 0 0 13.5Z"
    />
    <path
      fill="currentColor"
      d="M15.18 8.82a4.502 4.502 0 0 0-6.37 0 4.503 4.503 0 0 0 0 6.37"
      opacity={0.5}
    />
    <path
      fill="currentColor"
      d="M8.82 15.18a4.502 4.502 0 0 0 6.37 0 4.503 4.503 0 0 0 0-6.37"
    />
  </svg>
);
export default SvgDualLightsColorChip;
