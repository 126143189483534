import * as React from "react";
import type { SVGProps } from "react";
const SvgRectangle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="#707072"
    viewBox="0 0 29 29"
    {...props}
  >
    <path strokeWidth={1.555} d="M1.199 1.216h26.438v26.438H1.199z" />
  </svg>
);
export default SvgRectangle;
