import * as React from "react";
import type { SVGProps } from "react";
const SvgWhiteboard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10.5 8.25h6M10.5 12h6"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M18 20.25H6A2.25 2.25 0 0 1 3.75 18V6A2.25 2.25 0 0 1 6 3.75h12A2.25 2.25 0 0 1 20.25 6v12A2.25 2.25 0 0 1 18 20.25Z"
      clipRule="evenodd"
    />
    <path stroke="currentColor" strokeWidth={1.5} d="M9 8.25H7.5M9 12H7.5" />
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10.5 15.75h6"
    />
    <path stroke="currentColor" strokeWidth={1.5} d="M9 15.75H7.5" />
  </svg>
);
export default SvgWhiteboard;
